<template>
  <v-card outlined>
    <v-card-title>
      {{ name }} <v-spacer></v-spacer>

      <v-btn
        outlined
        tile
        class="text-capitalize"
        :to="{ path: 'edit' }"
        append
        color="primary"
        ><v-icon left>mdi-clipboard-edit-outline</v-icon
        >{{ $t('actions.edit') }}</v-btn
      ></v-card-title
    >
    <v-list>
      <v-list-item>
        <avatar
          v-if="user.attributes.info.name"
          class="mr-4"
          :username="user.attributes.info.name"
        ></avatar>

        <v-list-item-content>
          <v-list-item-subtitle>{{
            user.attributes.info.name
          }}</v-list-item-subtitle>
          <v-list-item-title
            ><v-icon small class="mr-1" color="grey lighten-2"
              >mdi-email-outline</v-icon
            >{{ user.attributes.email }}</v-list-item-title
          >
          <v-list-item-title
            ><v-icon small class="mr-1" color="grey lighten-2"
              >mdi-phone-outline</v-icon
            >{{ user.attributes.phoneNumberString }}</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <base-address-card :address="userAddress" />
  </v-card>
</template>

<script>
import Avatar from 'vue-avatar'
import { mapGetters } from 'vuex'
export default {
  components: {
    Avatar
  },

  computed: {
    ...mapGetters('user', ['user']),
    name() {
      const { name, firstName, lastName } = this.user.attributes.info
      return name || `${firstName} ${lastName}`
    },
    email() {
      return this.user.attributes.email
    },
    phone() {
      return this.user.attributes.phoneNumber
    },
    userAddress() {
      return this.user.attributes.info.address
    }
  }
}
</script>

<style lang="scss" scoped></style>
