<template>
  <v-row dense>
    <template v-for="(document, i) in documents">
      <v-col :key="i" cols="12" md="6" lg="4">
        <v-hover v-slot:default="{ hover }">
          <BaseDocumentCard
            :key="i"
            :document="document"
            :class="{ 'on-hover': hover }"
            :is-active="hover ? true : false"
            :options="options"
          />
        </v-hover>
      </v-col>
    </template>
    <v-col>
      <v-alert v-if="!documents.length">
        <p class="text--secondary">{{ $t('alerts.noFilesUploaded') }}</p>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    resourceId: {
      type: String,
      default: ''
    },
    resourceType: {
      type: String,
      default: ''
    },
    options: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('document', ['documents'])
  },
  async created() {
    await this.fetchDocuments()
  },
  methods: {
    async fetchDocuments() {
      const resourceId = this.resourceId
      const resourceType = this.resourceType
      await this.$store.dispatch('document/filter', {
        resourceType,
        resourceId,
        set: true
      })
    }
  }
}
</script>
<style scoped>
.v-card {
  transition: opacity 0.2s ease-in-out;
}

.v-card:not(.on-hover) {
  opacity: 0.7;
}
</style>
