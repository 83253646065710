<template>
  <base-secondary-table-layout
    icon="mdi-book-outline"
    :card-title="$tc('document.name', 2)"
  >
    <template v-slot:button>
      <base-new-outlined-button
        :to="{
          name: 'document-new',
          query: { resourceType, resourceId }
        }"
        >{{ $tc('document.name', 1) }}</base-new-outlined-button
      >
    </template>
    <DocumentIndexCards
      :resource-id="resourceId"
      :resource-type="resourceType"
    />
  </base-secondary-table-layout>
</template>

<script>
import DocumentIndexCards from './DocumentIndexCards'
export default {
  components: {
    DocumentIndexCards
  },
  props: {
    resourceId: {
      type: String,
      default: ''
    },
    resourceType: {
      type: String,
      default: ''
    }
  }
}
</script>
