<template>
  <v-row>
    <v-col cols="12"> <UserDetailsCard /></v-col>
    <v-col cols="12"><UserRelatedInfoCard /> </v-col>
    <v-col cols="12">
      <DocumentIndex :resource-id="userId" resource-type="User"
    /></v-col>
  </v-row>
</template>

<script>
import UserDetailsCard from './components/UserDetailsCard'
import DocumentIndex from '@/views/document/components/DocumentIndex'
import UserRelatedInfoCard from '@/views/user/components/UserRelatedInfoCard'
export default {
  components: {
    UserRelatedInfoCard,
    UserDetailsCard,
    DocumentIndex
  },
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch('user/fetch', this.userId)
    }
  }
}
</script>

<style lang="scss" scoped></style>
